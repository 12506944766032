import React from 'react';
import spread from '../assets/butter_spread_long.png';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-class">
        <div className="butter-spread" style={{backgroundImage: `url(${spread})` }}></div>
        some random content
      </div>
    );
  }
}

export default Footer
