import React from "react";
import axios from "axios";

import Card from "../components/card";
import './inventory.css';

class Inventory extends React.Component{
  state={
    inventory: [],
    // player: {
    //   currentArtAlbum: '...',
    //   nowPlaying: '...',
    //   album: '...',
    //   artist: '...',
    //   songName: '...'
    // }
  }
  
  componentDidMount() {
    axios.get('https://six.butterimage.com/get_butter-sight.php')
      .then(response=>{
        this.setState({inventory: response.data})
      });
  }
/**[{"id": "6",
 * "art_txt": "deco_000",
 * "artist_txt": "Mr Butter",
 * "art_collection": "PATTERN",
 * "art_type": "VECTOR",
 * "urlAssetContent": "sight_63180fa024d84_8b790094b6.svg",
 * "urlThumbContent": "thumb_sight_63180fa024d84_8b790094b6.gif"]
 * 
 */
  handleClick = (obj) => {
    // Changing state
    // this.setState({ currentArtAlbum: obj.urlAssetArtCover });
    // this.setState({ songName: obj.track_txt});
    // this.setState({ nowPlaying: obj.urlAssetContent });
    // this.setState({ album: obj.album_txt });
    // this.setState({ artist: obj.artist_txt });
  }

  render () {
    return <div className="container">
        {this.state.inventory.map((object)=>{
            return <div className="card">
                <Card 
                  id={object.id} art_txt={object.art_txt}
                  artistName={object.artist_txt}
                  art_collection={object.art_collection}
                  art_type={object.art_type}
                  urlAssetContent={object.urlAssetContent}
                  urlThumbContent={object.urlThumbContent}
                  alt_txt={object.alt_txt}/>
                </div>
        })}
    </div>
  }
}
export default Inventory
