import React from 'react';
import logo from '../assets/butter_logo_250.png';
import spread from '../assets/butter_spread_long.png';

class Header extends React.Component {
  render() {
    return (
      <div>
        <br/>
        <butter-banner>
          <img className="badge-img" src={logo} alt="butterimage logo" />
          <span className="badge-txt">sight.butterimage</span>
        </butter-banner>
        <br/>
        <div className="butter-spread" style={{backgroundImage: `url(${spread})` }}></div>
      </div>
    );
  }
}

export default Header
