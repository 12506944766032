import { Component } from "react";
import './App.css';
import Inventory from './base-elements/inventory';
import spread from './assets/butter_spread_long.png';

import ReactGA from 'react-ga4';

class App extends Component {
  verb=['behold','descry','discern','notice','observe','perceive','view','witness'];
  noun=['world','environmnet','self','image','nature','cosmos'];
  exaggerator=['better','clearer','vivider','finer','worthier','higher','further']

  getRandom(items)
  {
    return items[Math.floor(Math.random()*items.length)];    
  }

  initReactGA = () => {
    ReactGA.initialize('G-9L4WNFRVG9');
    ReactGA.send('pageview');
  };
  componentDidMount(){
    this.initReactGA();
  }
  render (){
    return(
      <div className="App">
        <h1>. butter sense through sight .</h1>
        . {this.getRandom(this.verb)} {this.getRandom(this.noun)} {this.getRandom(this.exaggerator)} .
        <div className="butter-spread" style={{backgroundImage: `url(${spread})` }}></div>
        <Inventory/>
      </div>
    );
  }
}

export default App;
