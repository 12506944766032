import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import BUTT_ER from './assets/IMG_2845.gif'

import Header from './base-elements/header';
import Nav from './base-elements/nav';
import Footer from './base-elements/footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Header />
      <Nav />
      <div>
        <Routes>
          <Route path="/" exactly element={<App />} />
          <Route
            path="*"
            element={
              <splash>
                <h2>404 Page not found etc</h2>
                <img src={BUTT_ER} alt="butter spin" />
              </splash>
            }
          />
        </Routes>
      </div>
      <Footer />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
