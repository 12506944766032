import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import axios from "axios";

import './card.css';

class Card extends Component {

  handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      saveAs(res.data, filename);
    })
  }

  constructURL(){
    return 'https://six.butterimage.com/get-asset.php?sense=sight&collection='+this.props.art_collection+'&asset='+this.props.urlAssetContent;
  }
  constructFileName(){
    var fileName = this.props.art_txt;
    var ext =  this.props.urlAssetContent.split('.').pop()
    fileName = fileName.replace(/\s/g, '');
    return fileName+"."+ext;
  }

  render(){
    return (
      <div>
        <div className="title">{this.props.art_txt}</div>
        <div className="content"> 
          <div className="more-info">By: {this.props.artistName}</div>
          <div className="more-info">FileType: {this.props.art_type}</div>
            <img className="imgPrev" src={'https://six.butterimage.com/sense/sight/'+this.props.art_collection+'/'+this.props.urlThumbContent} 
              alt={this.props.alt_txt}></img>
        </div>

        <div className="download" onClick={() => {this.handleDownload(this.constructURL(), this.constructFileName())}}>Download</div>
      </div>
    )
  }
}

export default Card;
